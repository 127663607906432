import './selector.scss';

class Selector {
    constructor (element, options) {
        const defaults = {
            toggleAttr: 'data-selector-toggle',
            labelAttr: 'data-selector-label',
            optionsAttr: 'data-selector-options',
            valueAttr: 'data-selector-value',
            onChanged: null
        };

        this.settings = Object.assign({}, defaults, options);
        this.$selector = element;

        this.$selectorToggle = this.$selector.querySelector('[' + this.settings.toggleAttr + ']');
        this.$selectorLabel = this.$selector.querySelector('[' + this.settings.labelAttr + ']');
        this.$selectorOptions = this.$selector.querySelectorAll('[' + this.settings.valueAttr + ']');
        this.$checkedOption = this.$selector.querySelector('[' + this.settings.valueAttr + ']:checked');

        this.initialize();
    }

    initialize () {
        this.setActiveValue(this.$checkedOption);
        this.setEvents();
    }

    setActiveValue (input) {
        const currentValue = input.getAttribute(this.settings.valueAttr);
        this.$selectorLabel.innerHTML = currentValue;
    }

    setEvents () {
        this.$selectorOptions.forEach(option => {
            option.addEventListener('change', () => {
                this.$selectorToggle.checked = false;

                this.setActiveValue(option);

                if (typeof this.settings.onChanged === 'function') {
                    this.settings.onChanged(option.value);
                }
            });
        });
    }
}

export { Selector };
